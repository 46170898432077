import React, { useState } from "react";
import { styled } from "../../stitches.config";
import { h2, bodyLarge, h4Strong } from "../commonStyles/Fonts";
import { ReactComponent as Arrow } from "../assets/icons/arrow-right.svg";
import { ReactComponent as StarEmpty } from "../assets/icons/subscribe-star-empty.svg";
import SubscribeButton from "../SubscribeButton";
import { useWindowWidth } from "../../helpers/useWindowWidth";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import News from "../../components/composition-components/box/News";
import Stitches from "@stitches/react";
import CategoryInfoSign from "../components/CategoryInfoSign";

import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../../components/common/constants/constants"


//import CategoryInfoSign from "../components/CategoryInfoSign"


interface HeadingProps {
  title: string;
  url?: string;
  btnSubscribe?: boolean;
  amount?: string;
  style?: string;
  subscribe?: boolean;
  specialHeading?: boolean;
  specialHeadingStyle?: "specialDots" | "fullDots" | "withoutDots";
  subscribeSection?: string;
  showMore?: boolean;
  infoSignText?: string;
}

const Heading: React.FC<HeadingProps> = ({
  title,
  url,
  btnSubscribe = false,
  amount,
  style = "",
  subscribe = false,
  specialHeading = false,
  specialHeadingStyle = "fullDots",
  subscribeSection = "",
  showMore = true,
  infoSignText,
  ...props
}) => {
  const [isActive, setActive] = useState(subscribe);
  //const [{ user }] = useAuth();
  const history = useHistory();

  const toggleClass = () => {
    setActive(!isActive);
  };

  const dotsStyle = specialHeadingStyle;

  let titleElement = <>{title}</>;
  if (url)
    titleElement = <a href={url}>{title}</a>;

  return (
    <div>
      <StyledHeading special={specialHeading} className={"style--" + style}>
        <StyledTitle className={h2() + ' heading-title'} special={specialHeading} dots={dotsStyle}>
          {dotsStyle == 'fullDots' || dotsStyle == 'specialDots' ? <Dots special={specialHeading} type="normal" /> : ''}
          {dotsStyle == 'withoutDots' ? <p>{titleElement}</p> : <span>{titleElement}</span>}
          {/* {user?.settings.followed_categories.length && (
              <StyledAmount className={h4Strong()} onClick={() => history.push('/dashboard')}>
                <StarEmpty />
                {user?.settings.followed_categories.length}
              </StyledAmount>
            )} */}
          {!specialHeading && btnSubscribe && !DISABLE_PREMIUM_FLAG_ACTIVE && <SubscribeButton subscribed={subscribe} subscribeSection={subscribeSection} isToggleIcon />}
          {infoSignText && !DISABLE_PREMIUM_FLAG_ACTIVE && <CategoryInfoSign infoSignText={infoSignText} />}
          {specialHeading && dotsStyle == 'specialDots' && <Dots special={specialHeading} type="empty" />}
        </StyledTitle>
        {(url && showMore) && (
          <StyledBtnMore className={bodyLarge()} href={url}>
            Więcej
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67387 2.00977L13.6638 6.99972L8.67387 11.9897L7.68392 10.9997L10.9837 7.69992L0.408142 7.69992V6.29992L10.9841 6.29992L7.68392 2.99972L8.67387 2.00977ZM0.583142 7.52492L11.4062 7.52492L7.93141 10.9997L8.67387 11.7422L8.67397 11.7421L7.93158 10.9997L11.4064 7.52489H0.583313L0.583313 6.47492H0.583142V7.52492ZM7.93141 2.99972L11.4066 6.47489H11.4068L7.93158 2.99969L8.67394 2.25732L8.67387 2.25725L7.93141 2.99972Z" />
            </svg>
          </StyledBtnMore>
        )}
      </StyledHeading>
    </div>
  );
};

const StyledHeading = styled("div", {
  width: "100%",
  display: "flex",
  variants: {
    special: {
      true: {
        alignItems: "center",
        "&.style--default-white": {
          color: "white",
          "span, a": {
            color: "white",
          }
        },
        "&.style--center-white": {
          color: "white",
          "span, a": {
            color: "white",
            textAlign: "center",
          }
        },
        "&.style--center-black": {
          color: "$grayscale100",
          "span, a": {
            color: "$grayscale100",
            textAlign: "center",
          }
        },
      },
      false: {
        justifyContent: "space-between",
        margin: "0 0 25px",
        "@bp4": {
          margin: "40px 0",
        }
      }
    }
  },
  // dodatkowe style dla Category, Category6
  "&.style--category-center-black": {
    "& .heading-title": {
      justifyContent: "flex-start",
      "@bp4": {
        justifyContent: "space-between",
        width: "100%",
      }
    },
    "span": {
      textAlign: "center",
      color: "$grayscale100",
    }
  },
  "&.style--category-center-white": {
    "& .heading-title": {
      justifyContent: "flex-start",
      "@bp4": {
        justifyContent: "space-between",
        width: "100%",
      }
    },
    "span": {
      textAlign: "center",
      color: "$grayscale0Fixed",
    }
  },
  "&.style--category-default-white": {
    "& .heading-title": {
      justifyContent: "flex-start",
    },
    "span": {
      color: "$grayscale0Fixed",
    }
  },
  "&.style--category-default-dark": {
    "& .heading-title": {
      justifyContent: "flex-start",
    },
  },
});

const StyledTitle = styled("div", {
  position: "relative",
  width: "100%",
  letterSpacing: "0px",
  display: "flex",
  "@bp4": {
    fontSize: "38px",
    lineHeight: "42px"
  },
  "& > span": {
    marginRight: "12px",
    color: "$grayscale100",
  },
  "a,a:focus,a:hover": {
    color: "inherit",
    textDecoration: "inherit"
  },
  variants: {
    special: {
      true: {
        padding: "0 0 12px",
        "& p": {
          width: "100%",
          textAlign: "center",
          margin: 0,
        },
        "@bp4": {
          padding: "40px 0"
        }
      },
      false: {
        margin: 0,
        paddingLeft: 0,
        justifyContent: "flex-start",
        alignItems: "center",
        "@bp4": {
          width: "auto",
          marginBottom: "3px",
        },
      }
    },
    dots: {
      fullDots: {
        justifyContent: "flex-start"
      },
      specialDots: {
        justifyContent: "flex-start",
        "@bp3": {
          justifyContent: "space-between",
        },
      },
      withoutDots: {}
    },
  },
  defaultVariants: {
    special: false
  }
});

const StyledBtnMore = styled("a", {
  color: "$primary",
  position: "relative",
  background: "none",
  borderWidth: "0px",
  display: "none",
  alignItems: "center",
  textDecoration: "none",
  fontWeight: "600",
  "&:hover": {
    color: "$primary120",
    textDecoration: "none",
  },

  "&:active": {
    color: "$primary",
    textDecoration: "none",
  },

  "@bp4": {
    display: "flex",
  },
  "& span": {
    content: "",
    // height: "10px",
    width: "14px",
    marginLeft: "6px",
  },
  "& svg": {
    marginLeft: "6px",
    position: "relative",
    top: "1px",
    "& path": {
      fill: "$primary",
    }
  },
});

const Dots = styled("div", {
  position: "relative",
  width: "8px",
  marginRight: "8px",
  flexShrink: 0,
  "&:after,&:before": {
    boxSizing: "border-box",
    content: "",
    position: "absolute",
    height: "8px",
    width: "100%",
    borderRadius: "50%",
    backgroundColor: "$primary",
    "@bp4": {
      height: "12px",
    },
  },
  "@bp4": {
    width: "12px",
    marginRight: "12px",
  },
  variants: {
    type: {
      normal: {
        marginRight: "8px",
        "@bp4": {
          marginRight: "12px",
        }
      },
      empty: {
        marginRight: '0px',
        display: "none",
        "&:after,&:before": {
          backgroundColor: "transparent",
          border: "1px solid $primary",
        },
        "@bp3": {
          display: "block"
        }
      }
    },
    special: {
      true: {
        height: "42px",
        "&:after": {
          top: "8px",
          left: "0px",
          "@bp4": {
            top: "6px",
          }
        },
        "&:before": {
          top: "20px",
          left: "0px",
          "@bp4": {
            top: "24px",
          }
        }
      },
      false: {
        height: "100%",
        "&:before": {
          left: "0px",
          top: "18px",
          "@bp4": {
            top: "24px",
          }
        },
        "&:after": {
          left: "0px",
          top: "8px",
        },
      }
    }
  }
});

const StyledAmount = styled("button", {
  marginLeft: "12px",
  padding: "4px 8px",
  border: "1px solid $grayscale25",
  display: "flex",
  alignItems: "center",
  background: "none",
  "& svg": {
    fill: "$primary",
    marginRight: "4px",
  },
});

export default Heading;