import React from "react";
import styled from "@emotion/styled";
import { bodySmall } from "../commonStyles/Fonts";

import InfoCategoryIcon from "../assets/icons/info-category.svg"

type CategoryInfoSignProps = {
    infoSignText?: string;
};

export const CategoryInfoSign: React.FC<CategoryInfoSignProps> = ({
    infoSignText,
    ...props
}) => {
    return (
        <WrapperCategoryInfoSign>
            <Icon>
                <Info className={bodySmall()}>{infoSignText}</Info>
            </Icon>
        </WrapperCategoryInfoSign>
    );
};

const WrapperCategoryInfoSign = styled.div`
    position: relative;
`;

const Icon = styled.div` 
    position: relative;
    width: 24px;
    height: 24px;
    background-image: url(${InfoCategoryIcon});
    background-position: center;
    background-size: cover;
    &:hover {
        div {
            display: block;
        }
    }
`;

const Info = styled.div` 
  position: absolute;
  top: -66px;
  background: #131212;
  color: white;
  width: 166px;
  left: -142px;
  padding: 4px 6px;
  display: none;
    @media (min-width: 996px) {
        left: 0px;
    }
   /*
  &:after {
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background:  #131212;
      content: " ";
      top: 51px;
      left: 149px;

      @media (min-width: 996px) {
          left: 7px;
      }
  }*/
`;

export default CategoryInfoSign;
